<template>
    <div class="beregner">
        <button class="hidden" v-shortkey="['ctrl', 'shift', 'l']" @shortkey="getDeeplink()"></button>

        <transition name="fadeIn">
			<DeeplinkPopup v-if="deeplinkPopupActive" :deeplink-string="deeplinkString" />
        </transition>

		<VisualHouseGraphic v-if="showResult === false && categoryType === ''" :construction-type="constructionType" :visual-house-image="visualHouseImage" />

        <div class="container stamp-bg">
            <GlobalLinks v-if="!showResult || isDesktop === false" />

            <div id="calculator-wrapper" :class="{ 'construction-selected' : constructionType !== '', 'w-full': showResult}">
				<ul id="type-mobile">
                    <li v-on:click="setType(key)" v-bind:class="[type === key ? 'selected': '']" v-bind:key="key" v-for="(theType,key) in _matrix.data.type.options"><div class="inner-rotate">{{theType.name}}</div></li>
                </ul>
                <div class="clearfix"></div>
                <div class="steps-handler">
                    <ul id="type">
                        <li v-on:click="setType(key)" v-bind:class="[type === key ? 'selected': '']" v-bind:key="key" v-for="(theType,key) in _matrix.data.type.options"><div class="inner-rotate gtm-track-usetype">{{theType.name}}</div></li>
                    </ul>
                    <ul id="steps-count">
                        <li v-bind:class="{done : inArray(step,stepped)}" v-bind:key="step" v-for="step in steps">{{step}}</li>
                    </ul>
                </div>

				<div v-if="!showResult || isDesktop === false" class="calc-content">
					<div class="intro">
						<h1>{{_dict.page.title}}</h1>
						<div class="teaser">{{_dict.page.teaser}}</div>
					</div>
					<div class="interaction-wrapper">
						<transition name="fadein">
							<div v-if="type !== ''" class="selectable-item-wrap">
								<ul v-if="constructionType !== ''" class="selected-ul">
									<li v-on:click="reset('constructionType')" class="selected">{{_matrix.data.type.options[type].options[constructionType].name}}</li>
								</ul>
								<div v-else>
									<div v-on:click="toggleDropdown" v-bind:class="{show : dda}" class="select-box-wrapper">
										<div class="toggleText">{{_matrix.data.type.options[type].data.toggleText}}</div>
										<ul>
											<li class="gtm-track-constructiontype" v-on:click="setConstructionType(key)" v-bind:key="key" v-for="(constructionType,key) in _matrix.data.type.options[type].options">{{constructionType.name}}</li>
										</ul>
									</div>
									<div v-on:click="setPopupData(_matrix.data.type.options[type].data)" v-if="_matrix.data.type.options[type].data.popup !== ''" class="helperBtn" data-help-type="Valg af konstruktiontype">{{_dict.page.getHelpText}}</div>
								</div>
							</div>
						</transition>
						<transition name="fadein">
							<div v-if="constructionType !== ''" class="selectable-item-wrap">
								<ul v-if="categoryType !== ''" class="selected-ul">
									<li v-on:click="reset('categoryType')" class="selected">{{_matrix.data.type.options[type].options[constructionType].options[categoryType].name}}</li>
								</ul>
								<div v-else>
									<div v-on:click="toggleDropdown" v-bind:class="{show : dda}" class="select-box-wrapper">
										<div class="toggleText">{{_matrix.data.type.options[type].options[constructionType].data.toggleText}}</div>
										<ul>
											<li class="gtm-track-categorytype" v-on:click="setCategoryType(key)" v-bind:key="key" v-for="(categoryType,key) in _matrix.data.type.options[type].options[constructionType].options">
												{{categoryType.name}}
												<ul class="sub" v-if="_matrix.data.type.options[type].options[constructionType].options[key].sub">
													<li v-bind:key="item" v-for="item in _matrix.data.type.options[type].options[constructionType].options[key].sub">- {{item}}</li>
												</ul>
											</li>
										</ul>
									</div>
									<div v-on:click="setPopupData(_matrix.data.type.options[type].options[constructionType].data)" v-if="_matrix.data.type.options[type].options[constructionType].data.popup !== ''" class="helperBtn" data-help-type="Valg af kategoritype">{{_dict.page.getHelpText}}</div>
								</div>
							</div>
						</transition>

						<transition name="fadein">
							<div v-if="categoryType !== '' && (constructionType === 'cat1' || constructionType === 'cat2') && !isFoundationBlocks()" class="selectable-item-wrap">
								<ul v-if="geometryType !== ''" class="selected-ul">
									<li v-on:click="reset('geometryType')" class="selected">{{_matrix.data.type.options[type].options[constructionType].options[categoryType].options[geometryType].name}}</li>
								</ul>
								<ul v-if="subCategoryType !== ''" class="selected-ul">
									<li v-on:click="reset('subCategoryType')" class="selected">{{_matrix.data.type.options[type].options[constructionType].options[categoryType].options[subCategoryType].name}}</li>
								</ul>
								<div v-if="geometryType === '' && subCategoryType === ''">
									<div v-if="constructionType === 'cat1'" class="select-shape-wrapper">
										<div class="infoText">{{_dict.geometryType.selectShape}}</div>
										<ul id="geometryItems">
											<li v-on:click="setGeometryType(key)" v-bind:key="key" v-for="(geometryType,key) in _matrix.data.type.options[type].options[constructionType].options[categoryType].options">
												<div class="shape gtm-track-geometrytype" v-bind:class="key">{{geometryType.name}}</div>
												<div class="shape-name gtm-track-geometrytype">{{geometryType.name}}</div>
											</li>
											<div class="clearfix"></div>
										</ul>
									</div>
									<div v-else>
										<div v-on:click="toggleDropdown" v-bind:class="{show : dda}" class="select-box-wrapper">
											<div class="toggleText">{{_matrix.data.type.options[type].options[constructionType].data.toggleText}}</div>
											<ul>
												<li class="gtm-track-subcategorytype" v-on:click="setSubCategoryType(key)" v-bind:key="key" v-for="(subCategoryType,key) in _matrix.data.type.options[type].options[constructionType].options[categoryType].options">{{subCategoryType.name}}</li>
											</ul>
										</div>
										<div v-on:click="setPopupData(_matrix.data.type.options[type].options[constructionType].options[categoryType].data)" v-if="_matrix.data.type.options[type].options[constructionType].options[categoryType].data.popup !== ''" class="helperBtn" data-help-type="Valg af sub-kategoritype">{{_dict.page.getHelpText}}</div>
									</div>
								</div>
							</div>
						</transition>

						<transition name="fadein">
							<div v-if="((constructionType === 'cat1' || constructionType === 'cat2') && (geometryType !== '' || subCategoryType !== '')) || ((constructionType === 'cat3' || constructionType === 'cat4') && categoryType !== '') || isFoundationBlocks()" class="selectable-item-wrap">
								<ul v-if="showResult" class="selected-ul">
									<li v-on:click="reset('formData')" class="selected">{{_dict.formData.enterMeasurements}}</li>
								</ul>
								<div v-else class="select-shape-wrapper">
									<div class="infoText">{{_dict.formData.enterMeasurements}}</div>

									<div class="shapeIllu">
										<figure v-if="geometryType === '' && isFoundationBlocks()" style="margin: 1.5rem 0 0 0;">
                                        	<img :src="fundamentsblokImg" height="188" width="371"  alt="foundation block">
										</figure>
                                        <span v-if="geometryType === 'shape1'" class="prisme">
                                            <svg width="181px" height="125px" viewBox="0 0 181 125" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <g id="model_prisme" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <path d="M0.5,42.9967672 L20.3708218,108.5 L165.5,108.5 L165.5,68.1415211 L125.72146,3.50139355 L0.5,4.12509394 L0.5,42.9967672 Z" id="Rectangle" stroke="#606060" fill="#F5F5F5" fill-rule="nonzero"></path>
                                                    <path d="M0.673967868,3.5 L20.3709376,68.5 L165.11231,68.5 L125.71837,3.5 L0.673967868,3.5 Z" id="Rectangle" stroke="#606060" fill="#F5F5F5" fill-rule="nonzero"></path>
                                                    <text id="B" font-family="D-DIN-Bold, D-DIN" font-size="14" font-weight="bold" fill="#A30044">
                                                        <tspan x="91" y="123">B</tspan>
                                                    </text>
                                                    <path d="M20.5,109 L20.5,69" id="Line-7" stroke="#606060" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <text id="H" font-family="D-DIN-Bold, D-DIN" font-size="14" font-weight="bold" fill="#A30044">
                                                        <tspan x="172" y="94">H</tspan>
                                                    </text>
                                                    <text id="L" font-family="D-DIN-Bold, D-DIN" font-size="14" font-weight="bold" fill="#A30044">
                                                        <tspan x="154" y="34">L</tspan>
                                                    </text>
                                                    <path d="M20.5,118.34 L83,118.34" id="Line-8" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M20.5,118.34 L83,118.34" id="Line-8" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M108.014648,118.34 L165,118.34" id="Line-8" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M176.5,109 L176.5,99.9375" id="Line-10" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M176.5,78.4267578 L176.5,69.8447266" id="Line-10" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M176.5,65.5 L161.5,39.5" id="Line-11" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M149.5,22 L136.5,0.5" id="Line-11" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                </g>
                                            </svg>
                                        </span>
										<span v-if="geometryType === 'shape2'" class="cylinder">
                                            <svg width="174px" height="117px" viewBox="0 0 174 117" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <g id="model_cylinder" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <text id="H" font-family="D-DIN-Bold, D-DIN" font-size="14" font-weight="bold" fill="#A30044">
                                                        <tspan x="165" y="72">H</tspan>
                                                    </text>
                                                    <path d="M169.5,97.5 L169.5,77.9375" id="Line-10" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M169.5,56.4267578 L169.5,26" id="Line-10" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M0,97 L0,26 L158,26 L158,97 C158,101.970563 122.630495,106 79,106 C35.3695048,106 0,101.970563 0,97 Z" id="Combined-Shape" stroke="#606060" fill="#F5F5F5"></path>
                                                    <ellipse id="Oval" stroke="#606060" fill="#F5F5F5" cx="79" cy="26" rx="79" ry="9"></ellipse>
                                                    <text id="R" font-family="D-DIN-Bold, D-DIN" font-size="14" font-weight="bold" fill="#A30044">
                                                        <tspan x="119" y="12">R</tspan>
                                                    </text>
                                                    <path d="M79,7.34 L111,7.34" id="Line-8" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M136.014648,7.34 L158,7.34" id="Line-8" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M79,0 L79,117.15625" id="Line-17" stroke="#747474" stroke-linecap="square" stroke-dasharray="2"></path>
                                                </g>
                                            </svg>
                                        </span>
										<span v-if="geometryType === 'shape3'" class="triangle">
                                            <svg width="170px" height="118px" viewBox="0 0 170 118" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <g id="model_trekant" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <g id="Group" stroke="#606060">
                                                        <path d="M0.5,39.1637119 L40.2809556,104.5 L155.5,104.5 L155.5,64.4799337 L0.5,0.746211132 L0.5,39.1637119 Z" id="Rectangle" fill="#F5F5F5" fill-rule="nonzero"></path>
                                                        <polyline id="Line-18" stroke-linecap="square" points="40.5 105 40.5 64.3310547 154.602539 64.3310547"></polyline>
                                                        <path d="M40.5,64.3310547 L1.5,2.5" id="Line-19" stroke-linecap="square"></path>
                                                    </g>
                                                    <text id="B" font-family="D-DIN-Bold, D-DIN" font-size="14" font-weight="bold" fill="#A30044">
                                                        <tspan x="96" y="118">B</tspan>
                                                    </text>
                                                    <text id="H" font-family="D-DIN-Bold, D-DIN" font-size="14" font-weight="bold" fill="#A30044">
                                                        <tspan x="161" y="89">H</tspan>
                                                    </text>
                                                    <path d="M41.5,113 L90.5,113.34" id="Line-8" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M111.5,113.34 L154,113.34" id="Line-8" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M165.5,104 L165.5,94.9375" id="Line-10" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M165.5,73.4267578 L165.5,64.8447266" id="Line-10" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <text id="L" font-family="D-DIN-Bold, D-DIN" font-size="14" font-weight="bold" fill="#A30044">
                                                        <tspan x="10" y="78">L</tspan>
                                                    </text>
                                                    <path d="M32.5,109.5 L17.5,83.5" id="Line-11" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                    <path d="M7.5,67.5 L0,54.5" id="Line-11" stroke="#A30044" stroke-linecap="square" fill-rule="nonzero"></path>
                                                </g>
                                            </svg>
                                        </span>
									</div>

									<ul id="data-input-holder">
										<li v-if="geometryType === 'shape1' || geometryType === 'shape3' || constructionType === 'cat2' || constructionType === 'cat4' || isFoundationBlocks()">
											<label>
												<strong>{{_dict.formData.length}} <span class="term">(m)</span></strong><br/>
												<input min="0" v-model="l" type="number" :placeholder="_dict.formData.length" @input="checkForReady">
											</label>
										</li>
										<li v-if="geometryType === 'shape1' || geometryType === 'shape3' || constructionType === 'cat3' || constructionType === 'cat4'">
											<label>
												<strong>{{_dict.formData.width}} <span class="term">(m)</span></strong><br/>
												<input min="0" v-model="w" type="number" :placeholder="_dict.formData.width" @input="checkForReady">
											</label>
										</li>
										<li v-if="geometryType === 'shape1' || geometryType === 'shape2' || geometryType === 'shape3' || constructionType === 'cat2' || constructionType === 'cat3' || isFoundationBlocks()">
											<label>
												<strong>{{_dict.formData.height}} <span class="term">(m)</span></strong><br/>
												<input min="0" v-model="h" type="number" :placeholder="_dict.formData.height" @input="checkForReady">
											</label>
										</li>
										<li v-if="isFoundationBlocks()">
											<label>
												<strong>{{_dict.formData.blockType}} (cm)<span class="term"></span></strong><br/>
												<select v-model.number="bt" @change="checkForReady">
													<option value="" selected>{{ _dict.formData.blockType }}</option>
													<option value="15">15 x 20 x 50</option>
													<option value="19">19 x 20 x 50</option>
													<option value="23">23 x 20 x 50</option>
													<option value="29">29 x 20 x 50</option>
													<option value="33">33 x 20 x 50</option>
													<option value="39">39 x 20 x 50</option>
												</select>
											</label>
										</li>
										<li v-if="geometryType === 'shape2'">
											<label>
												<strong>{{_dict.formData.radius}} <span class="term">(m)</span></strong><br/>
												<input min="0" v-model="r" type="number" :placeholder="_dict.formData.radius" @input="checkForReady">
											</label>
										</li>
										<li v-if="constructionType === 'cat3' || constructionType === 'cat4' && categoryType !== ''">
											<label>
												<strong>{{_dict.formData.thickness}} <span class="term">(mm)</span></strong><br/>
												<input min="0" v-model="t" type="number" :placeholder="_dict.formData.thickness" @input="checkForReady">
											</label>
										</li>
										<li v-if="constructionType === 'cat1' && categoryType !== 'typeTS' && categoryType !== 'type10'" class="stobemix-li-holder">
											<label class="checkbox-label-wrapper">
												<input v-bind:id="(!sm === true) ? 'med-stoebemix' : 'uden-stoebemix'" class="gtm-track-stobemix" v-on:click="toggleStobemix" v-bind:checked="sm" type="checkbox" /> {{_dict.formData.useStoebemix}}
											</label>
										</li>
										<div class="clearfix"></div>
									</ul>

									<transition name="fadein">
										<button v-on:click="calcResult" v-if="dataReady" id="calculate">{{_dict.formData.calculateBtnText}}</button>
									</transition>

								</div>
							</div>
						</transition>

						<transition name="fadein">
							<div v-if="showResult" class="selectable-item-wrap">
								<button v-on:click="reset('all')" id="restartCalculatorBtn">{{_dict.formData.restartBtnText}}</button>
							</div>
						</transition>

					</div>
				</div>

				<transition name="dropdown">
					<div v-if="showResult" id="receipt">
						<div class="inner-receipt-wrapper">
							<img class="aap-logo" width="225" height="37" :src="aapLogo" />
							<div class="clearfix"></div>

							<div class="heading">
								<div class="title">{{_dict.receipt.title}}</div>
							</div>
							<div class="column-one">

								<div class="choices section-box">
									<div class="sub-title">{{_dict.receipt.choicesTitle}}</div>
									<div class="choices-inner-wrapper">
										<strong>{{constructionTypeName}} / {{categoryTypeName}}</strong><div class="choices-spacer"></div>
										<div v-if="geometryTypeName">
											<strong v-if="geometryType !== ''">{{_dict.receipt.geometryTitle}}:</strong> <span v-if="geometryType !== ''">{{geometryTypeName}} </span> <span v-html="geometryOutput"></span>
										</div>
										<div v-if="subCategoryTypeName">
											<strong>{{_dict.receipt.brickTitle}}:</strong> <span>{{subCategoryTypeName}} </span> <span v-html="geometryOutput"></span>
										</div>
										<div v-if="constructionType !== 'cat1'">
											<div v-if="constructionType === 'cat3' || constructionType === 'cat4'"><span v-html="geometryOutput"></span></div>
											<strong>{{_dict.receipt.area}}:</strong> <span>{{areal}} m<sup>2</sup></span>
										</div>
									</div>
								</div>
								<div class="mixin section-box">
									<div class="sub-title">{{_dict.receipt.mixinTitle}}</div>
									<div class="mixin-inner-wrapper">
										<span v-html="mixinOutput"></span>
									</div>
								</div>
								<div class="volume section-box">
									<div class="sub-title">{{_dict.receipt.volumeTitle}}</div>
									<div v-if="constructionType === 'cat1'" class="sub-desc">{{_dict.receipt.volumeDesc}}: <strong>{{volumenToFixed}} m<sup>3</sup></strong></div>
									<div v-else-if="constructionType === 'cat4'" class="sub-desc">{{_dict.receipt.slidlagVolumeDesc}}: <strong>{{volumenToFixed}} liter</strong></div>
									<div v-else class="sub-desc">{{_dict.receipt.mortelVolumeDesc}}: <strong>{{volumenToFixed}} liter</strong></div>
									<div v-if="constructionType === 'cat1' || constructionType === 'cat4'" class="toggle-wrapper">
										<span>Vis forventet forbrug total i</span>
										<button class="toggle-visual-wrap" @click="(activeVolume === 'm3' ? activeVolume = 'kg' : activeVolume = 'm3')">
											<span class="item" :class="{'active': activeVolume === 'm3'}">m<sup>3</sup></span>
											<span class="item" :class="{'active': activeVolume === 'kg'}">kg</span>
											<span class="item highlight" :class="activeVolume"></span>
										</button>
									</div>

									<p v-if="constructionType === 'cat1' || constructionType === 'cat4'" class="info-text-for-toggle">
										<i v-if="activeVolume === 'm3'">(m<sup>3</sup>) viser forbruget målt i løsvægtsvolumen</i>
										<i v-if="activeVolume === 'kg'">(kg) viser forbruget målt som masse/vægt</i>
									</p>

									<div class="row">
										<div class="col show-splitter">
											<div class="sub-title" v-html="_dict.receipt.amountPerM3"></div>
											<table v-html="amountPerM3Output"></table>
										</div>
										<div class="col">
											<div class="sub-title" v-html="_dict.receipt.usageTotalTitle"></div>
											<table v-html="amountTotalM3Output"></table>
											<div class="pt-20">
												<table v-if="constructionType === 'cat1' || constructionType === 'cat4'">
													<tr>
														<td>{{_dict.receipt.bagsTotalText}}:</td>
														<td class="text-right"><strong>{{bagsTotal}}<span class="ending">stk</span></strong></td>
													</tr>
												</table>
												<table v-else>
													<tr>
														<td>{{_dict.receipt.bagsTotalText}}:</td>
														<td class="text-right" width="50"><strong>{{bagsTotal}}<span class="ending">stk</span></strong></td>
													</tr>
													<tr>
														<td>{{_dict.receipt.mortelBagsTotalText}}:</td>
														<td class="text-right" width="50"><strong>{{mortelBagsTotal}}<span class="ending">kg</span></strong></td>
													</tr>
												</table>
											</div>
											<div class="pt-20" v-if="isFoundationBlocks()">
												<table>
													<tr>
														<td>{{_dict.receipt.blocksTotalText}}:</td>
														<td class="text-right"><strong>{{blocksNeeded}}<span class="ending">stk</span></strong></td>
													</tr>
												</table>
											</div>
										</div>
									</div>

									<div class="amount">
										<div class="row">
											<div class="col"></div>
											<div class="col">

											</div>
										</div>
									</div>
								</div>
								<div class="imprint section-box">
									<div class="sub-title" v-html="_dict.receipt.imprintTitle"></div>
									<div class="imprint-inner-wrapper">
										<div v-for="type of recommendedCement"><span class="featuredImprint-wrapper" v-if="type === 'F'"><div class="featuredImprint"><span>CO<sub>2</sub> besparelse:</span> <span> {{getDiffByNextBestImprint(recommendedCement)}} kg CO<sub>2</sub></span></div></span><strong>{{ getImprintName(type) }}:</strong> {{ kg.toLocaleString("da-DK") }} kg cement = {{ getImprint(type) }} kg CO<sub>2</sub></div>
									</div>
								</div>
							</div>
							<div class="column-two">
								<div class="concreteProps section-box">
									<div v-if="constructionType === 'cat1'" class="sub-title">{{_dict.receipt.concretePropsTitle}}</div>
									<div v-else class="sub-title">{{_dict.receipt.mortelPropsTitle}}</div>
									<div class="concrete-props-inner-wrapper" v-html="concretePropsOutput"></div>
								</div>
								<div class="recommend section-box" v-if="recommendOutput">
									<div class="row">
										<div class="col">
											<div class="sub-title">{{_dict.receipt.recommendedTitle}}</div>
											<ul>
												<li v-bind:key="key" v-for="(cement,key) in recommendOutput"><strong>{{cement.name}}</strong></li>
											</ul>
										</div>
										<div class="col">
											<div class="inner-img-wrap">
												<div v-bind:class="{multiple: recommendOutput.length > 1}">
													<img v-for="(recomImg,index) in recommendOutput" v-bind:src="recomImg.img" :key="index" />
												</div>
											</div>
										</div>
										<div class="small-text" v-if="_matrix.data.type.options[type].options[constructionType].options[categoryType].data.environmentalImpact === 'E'">*AALBORG RAPID må ikke anvendes når SO<sub>4</sub><sup>2</sup> resulterer eksponeringsklassen XA3 jf. DS/EN 206 DK NA. I dette tilfælde kan AALBORG WHITE benyttes.</div>
									</div>
								</div>
								<div v-if="_matrix.data.type.options[type].name === 'Privat' && _matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.youtube !== '' && _matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.description !== '' && _matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.link !== ''" class="gds section-box">
									<div class="sub-title">Sådan gør du</div>
									<div v-if="_matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.youtube !== ''" class="responsive-video">
										<iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + _matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.youtube" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									</div>
									<p v-if="_matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.description !== ''">{{_matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.description}}</p>
									<p v-if="_matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.link !== ''"><a target="_blank" :href="_matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.link">{{_matrix.data.type.options[type].options[constructionType].options[categoryType].data.gds.link}}</a></p>
								</div>
								<div class="buttons">
									<button type="button" class="btn" @click="newCalculation">Lav ny beregning</button>
									<button type="button" class="btn-with-svg" @click="printReceipt">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36">
											<path fill="#fff" d="M24 12.45v-4.5H12v4.5H9.75001V5.7H26.25v6.75H24Zm2.7 6c.425 0 .7815-.144 1.0695-.432.287-.287.4305-.643.4305-1.068 0-.425-.1435-.7815-.4305-1.0695-.288-.287-.6445-.4305-1.0695-.4305-.425 0-.7815.1435-1.0695.4305-.287.288-.4305.6445-.4305 1.0695 0 .425.1435.781.4305 1.068.288.288.6445.432 1.0695.432ZM24 28.5v-6.4125H12V28.5h12Zm2.25 2.25H9.75001v-6h-5.55v-12.3H31.8v12.3h-5.55v6Zm3.3-8.25v-7.8H6.45001v7.8h3.3v-2.6625H26.25V22.5h3.3ZM6.45001 14.7H29.55 6.45001Z"/>
										</svg>
										Print
									</button>
								</div>
							</div>
						</div>
					</div>
				</transition>

                <div class="clearfix"></div>
            </div>


            <transition name="toggleSlide">
                <div v-if="popupActive" id="popup">
                    <div class="popupHeading">
                        <div class="title"><h4>{{_dict.page.helpText.title}}</h4></div>
                        <div v-on:click="closePopup" class="closePopupBtn">
                            <div class="text">{{_dict.page.helpText.closeHelp}}</div>
                            <div class="icon">+</div>
                        </div>
                    </div>
                    <div class="inner-popup-content" v-html="popupContent"></div>
                </div>
            </transition>

            <transition name="fadeIn">
                <div v-if="introPopupActive" id="intro-popup">
                    <div v-on:click="closeIntroPopup" class="closePopupBtn">
                        <div class="text">{{_dict.page.helpText.closeHelp}}</div>
                        <div class="icon">+</div>
                    </div>
                    <div class="inner-popup-content">
                        <span v-html="_matrix.data.type.data.popup.vejledning"></span>
                        <button v-on:click="closeIntroPopup">START BEREGNER</button>
                        <span v-html="_matrix.data.type.data.popup.disclaimer"></span>
                    </div>
                </div>
            </transition>

            <transition name="fadeIn">
                <div v-if="categoryType === 'typeTS' && tastSelvPopupSelected === false" id="tast-selv-popup">
                    <div v-on:click="closeTastSelvPopupBtn" class="closePopupBtn">
                        <div class="text">{{_dict.page.helpText.closeHelp}}</div>
                        <div class="icon">+</div>
                    </div>
                    <div class="inner-popup-content">
                        <h2>Tast selv</h2>
                        <p>Indtast dit selvvalgte blandingsforhold nedenfor og lad os regne resten ud for dig.</p>
                        <ul id="data-input-holder">
                            <li>
                                <label>
                                    <strong>Cement</strong><br/>
                                    <input readonly v-model.number="tastSelv.cement" type="number">
                                </label>
                            </li>
                            <li v-if="sm === false">
                                <label>
                                    <strong>Sand</strong><br/>
                                    <input v-model.number="tastSelv.sand" type="number">
                                </label>
                            </li>
                            <li v-if="sm === false">
                                <label>
                                    <strong>Sten</strong><br/>
                                    <input v-model.number="tastSelv.sten" type="number">
                                </label>
                            </li>
                            <li v-if="sm === true">
                                <label>
                                    <strong>Støbemix</strong><br/>
                                    <input v-model.number="tastSelv.stobemix" type="number">
                                </label>
                            </li>
                            <li class="stobemix-li-holder">
                                <label class="checkbox-label-wrapper">
                                    <input v-on:click="toggleStobemix" v-bind:checked="sm" type="checkbox" /> {{_dict.formData.useStoebemix}}
                                </label>
                            </li>
                            <div class="clearfix"></div>
                        </ul>
                        <button v-if="(sm && tastSelv.cement && tastSelv.stobemix) || (!sm && tastSelv.cement && tastSelv.sten && tastSelv.sand)" v-on:click="confirmTastSelv">Bekræft blandingsforhold</button>
                    </div>
                </div>
            </transition>

            <div class="clearfix"></div>

        </div>
    </div>
</template>
<script>
    import DeeplinkPopup from "@/components/DeeplinkPopup";
    import VisualHouseGraphic from "@/components/VisualHouseGraphic";
    import GlobalLinks from "@/components/GlobalLinks";
    const queryString = require('query-string');
    export default {
        name: 'home',
	    components: {GlobalLinks, VisualHouseGraphic, DeeplinkPopup},
	    data() {
            return {
				recommendedCement: [],
				kg: 0,
				isDesktop: false,
                deeplinkPopupActive: false,
                deeplinkString: "",
                l: "",
                w: "",
                h: "",
                bt: "",
                r: "",
                t: "",
                sm: false,
                areal: 0,
                volumen: 0,
				concreteVolume: '',
				blocksNeeded: 0,
	            activeVolume: 'm3',
                dataReady: false,
                showResult: false,
                dda: false,
                type: "private",
                steps: ["1","2","3","4","5"],
                stepped: ["1"],
                constructionType: "",
                tastSelv: {
                    cement: 1,
                    sand: false,
                    sten: false,
                    stobemix: false
                },
                categoryType: "",
                geometryType: "",
                subCategoryType: "",
                popupActive: false,
                introPopupActive: true,
                tastSelvPopupSelected: false,
                popupContent: "",
                airContent: 1.5,
                airContentS: 4,
                densitet: {
					water: 1000,
                    bulk: {
                        cement: 1150,
                        cementM: 1150, // 1250
                        cementS: 1150, // 1100
                        sand: 1300,
                        stone: 1500,
                        stobemix: 1630
                    },
                    abs: {
                        cement: 3100,
                        sand: 2585,
                        stone: 2585,
                        stobemix: 2585,
                        kalkmortel: 1700
                    }
                },
				fundamentsblokImg: require("@/assets/images/Wall_no_bg.png"),
                aapLogo: require("@/assets/images/aalborg-portland-logo.jpg"),
                aapCement: {
                    B: {
                        name: "BASIS AALBORG CEMENT",
                        img: require("@/assets/images/Aaborg_cement_basis_551x367.png"),
                        link: "#"
                    },
                    R: {
                        name: "RAPID AALBORG CEMENT",
                        img: require("@/assets/images/Aaborg_cement_rapid_551x367.png"),
                        link: "#"
                    },
                    W: {
                        name: "AALBORG WHITE CEMENT",
                        img: require("@/assets/images/Aaborg_cement_white_551x367.png"),
                        link: "#"
                    },
                    F: {
                        name: "FUTURECEM",
                        img: require("@/assets/images/Futurecem_551x367.png"),
                        link: "#"
                    }
                }
            }
        },
        mounted(){
			this.calcIsDesktop()
			window.addEventListener('resize', this.calcIsDesktop)
            // Deeplink
            let query = this.$route.query
            let queryItems = Object.keys(query)
            let hasQueryItems = false
            for(let q=0; q < queryItems.length; q++){
                if(typeof this[queryItems[q]] !== "undefined"){
                    if(hasQueryItems === false){
                        this.introPopupActive = false
                        hasQueryItems = true
                    }
                    this[queryItems[q]] = query[queryItems[q]]
                }
            }
        },
        computed: {
            _matrix: function() {
                return this.$store.state.matrix
            },
            _dict: function () {
                return this.$store.state.lingual[this.$store.state.currentLanguageCode]
            },
            visualHouseImage: function(){
                let item = false;
                if(this.constructionType !== ''){
                    if(this.constructionType === 'cat1'){
                        item = require('@/assets/drawings/choose-cat-1-4.svg');
                    }else if(this.constructionType === 'cat4'){
                        item = false
                    }else{
                        item = require('@/assets/drawings/choose-cat-2-3.svg');
                    }
                }else{
                    item = require('@/assets/drawings/choose-type.svg');
                }
                return item;
            },
            geometryOutput: function (){
                if(this.geometryType === 'shape1'){
                    // return this._dict.formData.length + ': <strong>' + this.l + ' m</strong> ' + this._dict.formData.width + ': <strong>' + this.w + ' m</strong> ' + this._dict.formData.height + ': <strong>'+ this.h +' m</strong>'
                    return `<strong>${this._dict.formData.length}: </strong>${this.l} m <strong>${this._dict.formData.width}: </strong>${this.w} m <strong>${this._dict.formData.height}: </strong> ${this.h} m`
                }
                if(this.geometryType === 'shape2'){
                    // return this._dict.formData.radius + ': <strong>' + this.r + ' m</strong> ' + this._dict.formData.height + ': <strong>' + this.h + ' m</strong>'
                    return `<strong>${this._dict.formData.radius}: </strong>${this.r} m <strong>${this._dict.formData.height}:</strong>${this.h} m`
                }
                if(this.geometryType === 'shape3'){
                    // return this._dict.formData.length + ': <strong>' + this.l + ' m</strong> ' + this._dict.formData.width + ': <strong>' + this.w + ' m</strong> ' + this._dict.formData.height + ': <strong>'+ this.h +' m</strong>'
                    return `<strong>${this._dict.formData.length}: </strong>${this.l} m <strong>${this._dict.formData.width}:</strong> ${this.w} m <strong>${this._dict.formData.height}:</strong> ${this.h} m`
                }
				if(this.isFoundationBlocks()){
					// return this._dict.formData.length + ': <strong>' + this.l + ' m</strong> ' + this._dict.formData.width + ': <strong>' + this.w + ' m</strong> ' + this._dict.formData.height + ': <strong>'+ this.h +' m</strong>'
					return `<strong>${this._dict.formData.length}: </strong>${this.l} m <strong>${this._dict.formData.width}: </strong>${this.bt} cm <strong>${this._dict.formData.height}:</strong> ${this.h} m`
				}
                if(this.constructionType === 'cat2'){
                    // return this._dict.formData.length + ': <strong>' + this.l + ' m</strong> ' + this._dict.formData.height + ': <strong>'+ this.h +' m</strong>'
                    return `<strong>${this._dict.formData.length}:</strong> ${this.l} m <strong>${this._dict.formData.height}:</strong> ${this.h} m`
                }
                if(this.constructionType === 'cat3'){
                    // return this._dict.formData.width + ': <strong>' + this.w + ' m</strong> ' + this._dict.formData.height + ': <strong>'+ this.h +' m</strong>' + this._dict.formData.thicknessPudslag + ': <strong>'+ this.t +' mm</strong>'
                    return `<strong>${this._dict.formData.width }:</strong>	${this.w} m <strong>${this._dict.formData.height}:</strong> ${this.h} m <strong>${this._dict.formData.thicknessPudslag}:</strong> ${this.t} mm`
                }
                if(this.constructionType === 'cat4'){
                    // return this._dict.formData.length + ': <strong>' + this.l + ' m</strong> ' + this._dict.formData.width + ': <strong>'+ this.w +' m</strong>' + this._dict.formData.thicknessSlidlag + ': <strong>'+ this.t +' mm</strong>'
                    return `<strong>${this._dict.formData.length}:</strong> ${this.l} m <strong>${this._dict.formData.width}:</strong> ${this.w} m <strong>${this._dict.formData.thicknessSlidlag}:</strong> ${this.t} mm`
                }
                return false;
            },
            mixinOutput: function(){
                if( this.constructionType !== '' && this.categoryType !== '' )
                {
                    let cementOutput = `<strong>${this._dict.receipt.cement}:</strong> ${this.getConstructionTypeData.cement}`
                    let sandOutput = `<strong>${this._dict.receipt.sand}:</strong> ${this.getNumberRealNumber(this.getConstructionTypeData.sand)}`
                    let stoneOutput = `<strong>${this._dict.receipt.stone}:</strong> ${this.getNumberRealNumber(this.getConstructionTypeData.stone)}`
                    let stobemixOutput = `<strong>${this._dict.receipt.stobemix}:</strong> ${this.getNumberRealNumber(this.getConstructionTypeData.stobemix)}`
                    let waterOutput = `<strong>${this._dict.receipt.water}:</strong> ${this.getNumberRealNumber(this.calcMaxWater.toFixed(1))}`

                    if( this.constructionType === 'cat1'){
                        if(this.sm){
                            return  cementOutput + " " + stobemixOutput + " " + waterOutput;
                        }else{
                            return  cementOutput + " " + sandOutput + " " + stoneOutput + " " + waterOutput;
                        }
                    }else if( this.constructionType === 'cat2' || this.constructionType === 'cat3' ){

                        cementOutput = `<strong>${this._dict.receipt.cement}:</strong> 1`
                        let kalkMortelData = this.getKalkmortelData;
                        let kalkMortelVolumeOutput = `<strong>${this._dict.receipt.kalkmortel}:</strong> ${parseFloat(kalkMortelData.volume.toFixed(0)).toLocaleString("da-DK")}`

                        return `${cementOutput} ${kalkMortelVolumeOutput}`
                    }else if( this.constructionType === 'cat4' ){
                        let oldWeight = this.getConstructionTypeData.oldSand.weight;
                        let oldDensity = this.getConstructionTypeData.oldSand.density;
                        let volumeSand = (1 - (oldWeight - oldDensity) / oldWeight) * oldDensity;
                        let roundValueVolumeSand = (Math.round(volumeSand * 4) / 4).toFixed(2);
                         sandOutput = `<strong>${this._dict.receipt.sand}:</strong> ${this.getNumberRealNumber(roundValueVolumeSand)}`
                        return `${cementOutput} ${sandOutput} ${waterOutput}`
                    }
                    else
                    {
                        return "<strong>Woops!</strong>";
                    }

                }
                return false;
            },
            concretePropsOutput: function(){
                if( this.constructionType !== '' && this.categoryType !== '' ) {

                    // let expectedStrengthClassOutput = this._dict.receipt.expectedStrengthClass + ': <strong>' + this.getConstructionTypeData.expectedStrengthClass + '</strong>';
                    let expectedStrengthClassOutput = `<strong>${this._dict.receipt.expectedStrengthClass}:</strong> ${this.getConstructionTypeData.expectedStrengthClass}`
                    expectedStrengthClassOutput = (typeof this.getConstructionTypeData.expectedStrengthClass === "undefined") ? '' : expectedStrengthClassOutput + '<br/>'
                    // let exposureClassesOutput = this._dict.receipt.exposureClasses + ': <strong>' + this.getConstructionTypeData.exposureClasses + '</strong>';
                    let exposureClassesOutput = `<strong>${this._dict.receipt.exposureClasses}:</strong> ${this.getConstructionTypeData.exposureClasses}`
                    exposureClassesOutput = (typeof this.getConstructionTypeData.exposureClasses === "undefined") ? '' : exposureClassesOutput + '<br/>'
                    // let environmentalImpactOutput = this._dict.receipt.environmentalImpact + ': <strong>' + this.getConstructionTypeData.environmentalImpact + '</strong>';
                    let environmentalImpactOutput = `<strong>${this._dict.receipt.environmentalImpact}:</strong> ${this.getConstructionTypeData.environmentalImpact}`
                    environmentalImpactOutput = (typeof this.getConstructionTypeData.environmentalImpact === "undefined") ? '' : environmentalImpactOutput

                    let baseOutput = expectedStrengthClassOutput + exposureClassesOutput + environmentalImpactOutput;

                    if(this.constructionType === "cat2" || this.constructionType === "cat3"){

                        let kalkMortelData = this.getKalkmortelData;
                        // let mortelTypeOutput = this._dict.receipt.mortelType + ': <strong>' + kalkMortelData.type + '</strong><br/>';
                        let mortelTypeOutput = `<strong>${this._dict.receipt.mortelType}:</strong> ${kalkMortelData.type}<br/>`
                        // let mortelPercentOutput = this._dict.receipt.usedMortel + ': <strong>'+ parseFloat((kalkMortelData.percent * 100).toFixed(1)).toLocaleString("da-DK") +' %</strong><br/>';
                        let mortelPercentOutput = `<strong>${this._dict.receipt.usedMortel}:</strong> ${parseFloat((kalkMortelData.percent * 100).toFixed(1)).toLocaleString("da-DK")}% <br/>`
                        // let mortelWaterContentOutput = this._dict.receipt.waterContent + ': <strong>' + parseFloat((kalkMortelData.waterPercent * 100).toFixed(0)).toLocaleString("da-DK") + ' %</strong><br/>';
                        let mortelWaterContentOutput = `<strong>${this._dict.receipt.waterContent}:</strong> ${parseFloat((kalkMortelData.waterPercent * 100).toFixed(0)).toLocaleString("da-DK")}% <br/>`
                        // let mortelWetRoom = this._dict.receipt.wetRoomWeight + ': <strong>' + parseFloat(kalkMortelData.wetRoom.toFixed(0)).toLocaleString("da-DK") +' kg/m<sup>3</sup></strong><br/>';
                        let mortelWetRoom = `<strong>${this._dict.receipt.wetRoomWeight}:</strong> ${parseFloat(kalkMortelData.wetRoom.toFixed(0)).toLocaleString("da-DK")} kg/m<sup>3</sup><br/>`

                        let mortelOutput = mortelTypeOutput + mortelPercentOutput + mortelWaterContentOutput + mortelWetRoom;
                        baseOutput = mortelOutput + baseOutput;
                    }

                    return baseOutput;
                }
                return false;
            },
            values: function(){

                if( this.constructionType !== '' && this.categoryType !== '' )
                {

                    if(this.constructionType === "cat1")
                    {
                              let absVolPerM3CementCalc = ((this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement)) / ((this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) + (this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) + (this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement)) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContent / 100);
                              if (this.sm === true) {
                                   absVolPerM3CementCalc = ((this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement)) / ((this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) + (this.getConstructionTypeData.stobemix * this.densitet.bulk.stobemix / this.densitet.abs.stobemix) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement)) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContent / 100);
                              }
                              let absVolPerM3SandCalc = ( ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) / ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) ) / ( ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) / ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) + ( this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement ) / ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) + ( this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone ) / ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) ) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContent / 100);
                        let absVolPerM3StoneCalc = ( ( this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone ) / ( this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone ) ) / ( ( this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone ) / ( this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone ) + ( this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement ) / ( this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone ) + ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) / ( this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone ) ) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContent / 100);
                        let absVolPerM3StobemixCalc = ( ( this.getConstructionTypeData.stobemix * this.densitet.bulk.stobemix / this.densitet.abs.stobemix ) / ( this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement ) ) / ( ( this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement ) / ( this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement ) + ( this.getConstructionTypeData.stobemix * this.densitet.bulk.stobemix / this.densitet.abs.stobemix ) / ( this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement ) ) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContent / 100);

                        let kgCement = (absVolPerM3CementCalc * this.densitet.abs.cement);

                        let sandM3 = (absVolPerM3SandCalc * this.densitet.abs.sand / this.densitet.bulk.sand);
                        let stoneM3 = (absVolPerM3StoneCalc * this.densitet.abs.stone / this.densitet.bulk.stone);
                        let stobemixM3 = (absVolPerM3StobemixCalc * this.densitet.abs.stobemix / this.densitet.bulk.stobemix);
                        let waterValue = this.getConstructionTypeData.maxWater;

                        return {
                            cement: (isNaN(kgCement)) ? 0 : kgCement,
                            sand: (isNaN(sandM3)) ? 0 : sandM3,
                            stone: (isNaN(stoneM3)) ? 0 : stoneM3,
                            stobemix: (isNaN(stobemixM3)) ? 0 : stobemixM3,
                            water: (isNaN(waterValue)) ? 0 : waterValue
                        }
                    }

                    if(this.constructionType === "cat4")
                    {
                        let absVolPerM3CementCalc = ( ( this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement ) / ( this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement ) ) / ( ( this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement ) / ( this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement ) + ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) / ( this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement ) ) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContentS / 100);
                        let absVolPerM3SandCalc = ( ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) / ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) ) / ( ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) / ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) + ( this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement ) / ( this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand ) ) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContentS / 100);

                        let kgCement = (absVolPerM3CementCalc * this.densitet.abs.cement);
                        let sandM3 = (absVolPerM3SandCalc * this.densitet.abs.sand / this.densitet.bulk.sand);
                        let waterValue = this.getConstructionTypeData.maxWater;

                        return {
                            cement: (isNaN(kgCement)) ? 0 : kgCement,
                            sand: (isNaN(sandM3)) ? 0 : sandM3,
                            water: (isNaN(waterValue)) ? 0 : waterValue
                        }
                    }

                }
                return false;

            },
            amountPerM3Output: function(){

                if( this.constructionType !== '' && this.categoryType !== '' ) {

                    if( this.constructionType === 'cat1' )
                    {
                        let values = this.values;
                        // let cementOutput = '<tr><td>' + this._dict.receipt.cement + '</td><td class="text-right"><strong>' + values.cement.toFixed(0) + '<span class="ending">kg</span></strong></td></tr>';
                        let cementOutput = `<tr><td>${this._dict.receipt.cement}:</td><td class="text-right"><strong>${values.cement.toFixed(0)}<span class="ending">kg</span></strong></td></tr>`
                        // let waterOutput = '<tr><td>' + this._dict.receipt.water + '</td><td class="text-right"><strong>' + values.water.toFixed(0) + '<span class="ending">l</span></strong></td></tr>';
                        let waterOutput = `<tr><td>${this._dict.receipt.water}:</td><td class="text-right"><strong>${values.water.toFixed(0)}<span class="ending">l</span></strong></td></tr>`

                        if(this.sm){
                            // let stobemixOutput = '<tr><td>' + this._dict.receipt.stobemix + '</td><td class="text-right"><strong>' + parseFloat(values.stobemix.toFixed(2)).toLocaleString("da-DK") +'<span class="ending">m<sup>3</sup></span></strong></td></tr>';
                            let stobemixOutput = `<tr><td>${this._dict.receipt.stobemix}</td><td class="text-right"><strong>${parseFloat(values.stobemix.toFixed(2)).toLocaleString("da-DK")}<span class="ending">m<sup>3</sup></span></strong></td></tr>`
                            return cementOutput + stobemixOutput + waterOutput;
                        }else{
                            // let sandOutput = '<tr><td>' + this._dict.receipt.sand + '</td><td class="text-right"><strong>' + parseFloat(values.sand.toFixed(2)).toLocaleString("da-DK") +'<span class="ending">m<sup>3</sup></span></strong></td></tr>';
                            let sandOutput = `<tr><td>${this._dict.receipt.sand}:</td><td class="text-right"><strong>${parseFloat(values.sand.toFixed(2)).toLocaleString("da-DK")}<span class="ending">m<sup>3</sup></span></strong></td></tr>`
                            // let stoneOutput = '<tr><td>' + this._dict.receipt.stone + '</td><td class="text-right"><strong>' + parseFloat(values.stone.toFixed(2)).toLocaleString("da-DK") +'<span class="ending">m<sup>3</sup></span></strong></td></tr>';
                            let stoneOutput = `<tr><td>${this._dict.receipt.stone}:</td><td class="text-right"><strong>${parseFloat(values.stone.toFixed(2)).toLocaleString("da-DK")}<span class="ending">m<sup>3</sup></span></strong></td></tr>`
                            return cementOutput + sandOutput + stoneOutput + waterOutput;
                        }
                    }

                    if( this.constructionType === 'cat2' || this.constructionType === 'cat3')
                    {

                        let kalkMortelData = this.getKalkmortelData;

                        let kgCement = 1 / (1 + kalkMortelData.weight) * kalkMortelData.wetRoom;

 /*                       let calcMortelUsageLiter = this.volumen * this.subCategoryTypeValue * 100;
                        let wetKalkUsageLiter = calcMortelUsageLiter * (kalkMortelData.volume / this.densitet.bulk.cementM * this.densitet.abs.cement) / ( 1 + kalkMortelData.volume / this.densitet.bulk.cementM * this.densitet.abs.cement );

                        kgCement = calcMortelUsageLiter - wetKalkUsageLiter;*/ // @todo: has been overwriten - test

                        let kgWetKalkMortel = kalkMortelData.weight / (1 + kalkMortelData.weight) * kalkMortelData.wetRoom;
                        let literWetKalkMortel = kgWetKalkMortel / this.densitet.abs.kalkmortel * 1000;

                        let cementOutput = `<tr><td>${this._dict.receipt.cement}:</td><td class="text-right"><strong>${parseFloat(kgCement.toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
                        let kalkMortelWetOutput = `<tr><td>${this._dict.receipt.kalkMortelWet}:</td><td class="text-right"><strong>${parseFloat(literWetKalkMortel.toFixed(0)).toLocaleString("da-DK")}<span class="ending">liter</span></strong></td></tr>`
                        return cementOutput + kalkMortelWetOutput
                    }

                    if( this.constructionType === 'cat4' )
                    {
                        let values = this.values;
                        let cementOutput = `<tr><td>${this._dict.receipt.cement}:</td><td class="text-right"><strong>${values.cement.toFixed(0)}<span class="ending">kg</span></strong></td></tr>`
                        let sandOutput = `<tr><td>${this._dict.receipt.sand}:</td><td class="text-right"><strong>${parseFloat(values.sand.toFixed(3)).toLocaleString("da-DK")}<span class="ending">m<sup>3</sup></span></strong></td></tr>`
                        let waterOutput = `<tr><td>${this._dict.receipt.water}:</td><td class="text-right"><strong>${values.water.toFixed(0)}<span class="ending">l</span></strong></td></tr>`
                        return cementOutput + sandOutput + waterOutput;
                    }

                }
                return false;
            },
            amountTotalM3Output: function(){
                if( this.dataReady ) {

                    if( this.constructionType === 'cat1' )
                    {
                        let values = this.values;

						this.kg = parseInt((values.cement * this.volumen).toFixed(0))

						if(this.activeVolume === 'm3'){
							let cementOutput = `<tr><td>${this._dict.receipt.cement}:</td><td class="text-right"><strong>${parseInt((values.cement * this.volumen / this.densitet.bulk.cement * 1000).toFixed(0)).toLocaleString("da-DK")}<span class="ending">l</span></strong></td></tr>`
							let waterOutput = `<tr><td>${this._dict.receipt.water}:</td><td class="text-right"><strong>${parseInt((values.water * this.volumen / this.densitet.water * 1000).toFixed(0)).toLocaleString("da-DK")}<span class="ending">l</span></strong></td></tr>`
							if (this.sm) {
								let stobemixOutput = `<tr><td>${this._dict.receipt.stobemix}:</td><td class="text-right"><strong>${parseFloat((values.stobemix * this.volumen).toFixed(2)).toLocaleString("da-DK")}<span class="ending">m<sup>3</sup></span></strong></td></tr>`
								return cementOutput + stobemixOutput + waterOutput
							} else {
								let sandOutput = `<tr><td>${this._dict.receipt.sand}:</td><td class="text-right"><strong>${parseFloat((values.sand * this.volumen).toFixed(2)).toLocaleString("da-DK")}<span class="ending">m<sup>3</sup></span></strong></td></tr>`
								let stoneOutput = `<tr><td>${this._dict.receipt.stone}:</td><td class="text-right"><strong>${parseFloat((values.stone * this.volumen).toFixed(2)).toLocaleString("da-DK")}<span class="ending">m<sup>3</sup></span></strong></td></tr>`
								return cementOutput + sandOutput + stoneOutput + waterOutput
							}
						}else if(this.activeVolume === 'kg'){
							let cementOutput = `<tr><td>${this._dict.receipt.cement}:</td><td class="text-right"><strong>${parseInt((values.cement * this.volumen).toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
							let waterOutput = `<tr><td>${this._dict.receipt.water}:</td><td class="text-right"><strong>${parseInt((values.water * this.volumen / this.densitet.water * 1000).toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
							if (this.sm) {
								let stobemixOutput = `<tr><td>${this._dict.receipt.stobemix}:</td><td class="text-right"><strong>${parseFloat((values.stobemix * this.volumen * this.densitet.bulk.stobemix).toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
								return cementOutput + stobemixOutput + waterOutput
							} else {
								let sandOutput = `<tr><td>${this._dict.receipt.sand}:</td><td class="text-right"><strong>${parseFloat((values.sand * this.volumen * this.densitet.bulk.sand).toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
								let stoneOutput = `<tr><td>${this._dict.receipt.stone}:</td><td class="text-right"><strong>${parseFloat((values.stone * this.volumen * this.densitet.bulk.stone).toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
								return cementOutput + sandOutput + stoneOutput + waterOutput
							}
						}
                    }

                    if( this.constructionType === 'cat2' || this.constructionType === 'cat3')
                    {

                        let kalkMortelData = this.getKalkmortelData;

                        let kgCement = 1 / (1 + kalkMortelData.weight) * kalkMortelData.wetRoom;

                        let calcMortelUsageTotalLiter = 0;
                        if(this.subCategoryType !== '')
                        {
                            calcMortelUsageTotalLiter = this.volumen * this.subCategoryTypeValue * 100;
                        }else{
                            calcMortelUsageTotalLiter = this.volumen * 1000;
                        }

                        let wetKalkUsageLiter = calcMortelUsageTotalLiter * (kalkMortelData.volume / this.densitet.bulk.cementM * this.densitet.abs.cement) / ( 1 + kalkMortelData.volume / this.densitet.bulk.cementM * this.densitet.abs.cement );

                        kgCement = calcMortelUsageTotalLiter - wetKalkUsageLiter;  // @todo: has been overwriten - test

                        let kgCementTotal = kgCement / 1000 * this.densitet.abs.cement;

						let cementOutput = `<tr><td>${this._dict.receipt.cement}:</td><td class="text-right"><strong>${parseFloat(kgCementTotal.toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
						this.kg = parseFloat(kgCementTotal.toFixed(0))
                        let kalkMortelWetOutput = '<tr><td>' + this._dict.receipt.kalkMortelWet + ':</td><td class="text-right"><strong>' + parseFloat(wetKalkUsageLiter.toFixed(0)).toLocaleString("da-DK") + '<span class="ending">liter</span></strong></td></tr>';
                        return cementOutput + kalkMortelWetOutput;
                    }

                    if( this.constructionType === 'cat4')
                    {
                        let values = this.values;
	                    if(this.activeVolume === 'm3') {
		                    let cementOutput = `<tr><td>${this._dict.receipt.cement}:</td><td class="text-right"><strong>${parseInt((values.cement * this.volumen / this.densitet.bulk.cement * 1000).toFixed(0)).toLocaleString("da-DK")}<span class="ending">l</span></strong></td></tr>`
		                    let sandOutput = `<tr><td>${this._dict.receipt.sand}:</td><td class="text-right"><strong>${parseFloat((values.sand * this.volumen).toFixed(2)).toLocaleString("da-DK")}<span class="ending">m<sup>3</sup></span></strong></td></tr>`
		                    let waterOutput = `<tr><td>${this._dict.receipt.water}:</td><td class="text-right"><strong>${parseInt((values.water * this.volumen).toFixed(0)).toLocaleString("da-DK")}<span class="ending">l</span></strong></td></tr>`
		                    return cementOutput + sandOutput + waterOutput;
	                    }else if(this.activeVolume === 'kg'){
		                    let cementOutput = `<tr><td>${this._dict.receipt.cement}:</td><td class="text-right"><strong>${parseInt((values.cement * this.volumen).toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
							this.kg = parseInt((values.cement * this.volumen).toFixed(0))
		                    let sandOutput = `<tr><td>${this._dict.receipt.sand}:</td><td class="text-right"><strong>${parseFloat((values.sand * this.volumen * this.densitet.bulk.sand).toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
		                    let waterOutput = `<tr><td>${this._dict.receipt.water}:</td><td class="text-right"><strong>${parseInt((values.water * this.volumen / this.densitet.water * 1000).toFixed(0)).toLocaleString("da-DK")}<span class="ending">kg</span></strong></td></tr>`
		                    return cementOutput + sandOutput + waterOutput;
						}
						return ''
                    }

                }
                return false;
            },
            recommendOutput: function(){
                if( this.constructionType !== '' && this.categoryType !== '' && this.getConstructionTypeData.recommendedCement !== "-" ) {
                    let recommendedCement = this.getConstructionTypeData.recommendedCement.split(",");
					this.recommendedCement = this.getConstructionTypeData.recommendedCement.split(",")
                    let buildOutput = [];
                    for (let c = 0; c < recommendedCement.length; c++) {
                        let id = recommendedCement[c];
                        let aapCementName = this.aapCement[id].name;
                        let aapCementImg = this.aapCement[id].img;
                        buildOutput.push({
                            name: aapCementName,
                            img: aapCementImg
                        });
                    }
                    return buildOutput;
                }
                return false;
            },
            volumenToFixed: function(){
                if(this.constructionType !== '' && this.constructionType === 'cat1') {
                    return parseFloat(this.volumen.toFixed(3)).toLocaleString("da-DK")
                }else if(this.constructionType !== '' && this.constructionType !== 'cat1'){
                    let calcMortelUsageTotalLiter = 0;
                    if(this.subCategoryType !== ''){
                        calcMortelUsageTotalLiter = this.volumen * this.subCategoryTypeValue * 100;
                    }else{
                        calcMortelUsageTotalLiter = this.volumen * 1000;
                    }
                    return Math.round(calcMortelUsageTotalLiter);
                }
                return false;
            },
            bagsTotal: function(){
                if( this.constructionType !== '' && this.categoryType !== '' ) {
                    if(this.constructionType === 'cat1' || this.constructionType === 'cat4'){
                        return Math.ceil(((this.values.cement * this.volumen)) / 25).toLocaleString("da-DK");
                    }else{
                        let kalkMortelData = this.getKalkmortelData;

                        let calcMortelUsageTotalLiter = 0;
                        if(this.subCategoryType !== '')
                        {
                            calcMortelUsageTotalLiter = this.volumen * this.subCategoryTypeValue * 100;
                        }else{
                            calcMortelUsageTotalLiter = this.volumen * 1000;
                        }

                        let wetKalkUsageLiter = calcMortelUsageTotalLiter * (kalkMortelData.volume / this.densitet.bulk.cementM * this.densitet.abs.cement) / ( 1 + kalkMortelData.volume / this.densitet.bulk.cementM * this.densitet.abs.cement );
                        let kgCement = calcMortelUsageTotalLiter - wetKalkUsageLiter;
                        let kgCementTotal = kgCement / 1000 * this.densitet.abs.cement;
                        return Math.ceil(((kgCementTotal)) / 25).toLocaleString("da-DK");
                    }
                }
                return false;
            },
            mortelBagsTotal: function(){
                if( this.constructionType !== '' && this.categoryType !== '' && this.constructionType !== "cat1" && this.constructionType !== "cat4") {
                    let kalkMortelData = this.getKalkmortelData;

                    let calcMortelUsageTotalLiter = 0;
                    if(this.subCategoryType !== '')
                    {
                        calcMortelUsageTotalLiter = this.volumen * this.subCategoryTypeValue * 100;
                    }else{
                        calcMortelUsageTotalLiter = this.volumen * 1000;
                    }

                    let wetKalkUsageLiter = calcMortelUsageTotalLiter * (kalkMortelData.volume / this.densitet.bulk.cementM * this.densitet.abs.cement) / ( 1 + kalkMortelData.volume / this.densitet.bulk.cementM * this.densitet.abs.cement );
                    let kgCement = calcMortelUsageTotalLiter - wetKalkUsageLiter;
                    let kgCementTotal = kgCement / 1000 * this.densitet.abs.cement;
                    let kalkmortelTotal = kgCementTotal / wetKalkUsageLiter * 100;
                    return Math.ceil(kalkmortelTotal).toLocaleString("da-DK");
                }
                return false;
            },
            constructionTypeName: function(){
                if(this.type !== '' && this.constructionType !== ''){
                    return this._matrix.data.type.options[this.type].options[this.constructionType].name
                }
                return false
            },
            categoryTypeName: function(){
                if(this.type !== '' && this.constructionType !== '' && this.categoryType !== ''){
                    return this._matrix.data.type.options[this.type].options[this.constructionType].options[this.categoryType].name
                }
                return false
            },
            subCategoryTypeName: function(){
                if(this.type !== '' && this.constructionType !== '' && this.categoryType !== '' && this.subCategoryType !== ''){
                    return this._matrix.data.type.options[this.type].options[this.constructionType].options[this.categoryType].options[this.subCategoryType].name
                }
                return false
            },
            subCategoryTypeValue: function(){
                if(this.type !== '' && this.constructionType !== '' && this.categoryType !== '' && this.subCategoryType !== ''){
                    return this._matrix.data.type.options[this.type].options[this.constructionType].options[this.categoryType].options[this.subCategoryType].value
                }
                return false
            },
            geometryTypeName: function(){
                if(this.type !== '' && this.constructionType !== '' && this.geometryType !== ''){
                    return this._matrix.data.type.options[this.type].options[this.constructionType].options[this.categoryType].options[this.geometryType].name
                } else if (this.isFoundationBlocks()){
					return true
				}
                return false
            },
            getConstructionTypeData(){
                var getData = this._matrix.data.type.options[this.type].options[this.constructionType].options[this.categoryType].data;
                if(this.categoryType === 'typeTS'){
                    getData.cement = this.tastSelv.cement
                    getData.stone = this.tastSelv.sten
                    getData.sand = this.tastSelv.sand
                    getData.stobemix = this.tastSelv.stobemix
                }
                return getData;
            },
            calcMaxWater: function(){
                if( this.constructionType !== '' && this.categoryType !== '' ) {
                    let maxWater = this._matrix.data.type.options[this.type].options[this.constructionType].options[this.categoryType].data.maxWater;
                    let absVolPerM3CementCalc = 0;

                    if(this.constructionType === 'cat1'){
                        absVolPerM3CementCalc = ((this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement)) / ((this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) + (this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) + (this.getConstructionTypeData.stone * this.densitet.bulk.stone / this.densitet.abs.stone) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement)) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContent / 100);
                        if (this.sm === true) {
                          absVolPerM3CementCalc = ((this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement)) / ((this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement) + (this.getConstructionTypeData.stobemix * this.densitet.bulk.stobemix / this.densitet.abs.stobemix) / (this.getConstructionTypeData.cement * this.densitet.bulk.cement / this.densitet.abs.cement)) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContent / 100);
                        }
                    }
                    if(this.constructionType === 'cat4'){
                        absVolPerM3CementCalc = ((this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement)) / ((this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement) / (this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement) + (this.getConstructionTypeData.sand * this.densitet.bulk.sand / this.densitet.abs.sand) / (this.getConstructionTypeData.cement * this.densitet.bulk.cementS / this.densitet.abs.cement) ) * (1 - this.getConstructionTypeData.maxWater / 1000 - this.airContentS / 100);
                    }
                    let kgCement = (absVolPerM3CementCalc * this.densitet.abs.cement);
                    return maxWater / 1000 / (kgCement / this.densitet.bulk.cement)
                }
                return false;
            },
            getKalkmortelData: function(){
                if( this.constructionType !== '' && this.categoryType !== '' ) {
                    let thisData = this._matrix.data.type.options[this.type].options[this.constructionType].options[this.categoryType].data;
                    let kalkhydrat = thisData.kalkhydrat;
                    let cement = thisData.cement;
                    let sand = thisData.sand;
                    let waterPercent = thisData.waterContent;

                    let percent = kalkhydrat / (kalkhydrat + sand);
                    let weight = (kalkhydrat + sand) * (1 + waterPercent) / cement;
                    let volume = (kalkhydrat + sand) * (1 + waterPercent) / cement * this.densitet.bulk.cement / this.densitet.abs.kalkmortel;

                    // Round weight
                    // let weightRounded = Math.round(weight);
                    // weight = weightRounded; //@todo: Temp – remove!
                    //
                    // // Round volume
                    // let volumeRounded = Math.round(volume);
                    // volume = volumeRounded; //@todo: Temp – remove!

                    let wetRoom = (1 + weight) / ( 1 / this.densitet.abs.cement + weight / this.densitet.abs.kalkmortel);

                    return {
                        waterPercent: waterPercent,
                        percent: percent,
                        weight: weight,
                        volume: volume,
                        type: "KC " + kalkhydrat + "/" + cement + "/" + sand,
                        wetRoom: wetRoom
                    }
                }
                return false;
            }
        },
        methods: {
			getDiffByNextBestImprint(types){
				let imprintMap = {
					F: .574,
					B: .728,
					R: .803,
					W: 1.04
				}
				if(types.includes('B'))
					return Number((parseInt(this.kg) * imprintMap['B']).toFixed(0) - (parseInt(this.kg) * imprintMap['F']).toFixed(0)).toLocaleString('da-DK')
				if(types.includes('R'))
					return Number((parseInt(this.kg) * imprintMap['R']).toFixed(0) - (parseInt(this.kg) * imprintMap['F']).toFixed(0)).toLocaleString('da-DK')
				if(types.includes('W'))
					return Number((parseInt(this.kg) * imprintMap['W']).toFixed(0) - (parseInt(this.kg) * imprintMap['F']).toFixed(0)).toLocaleString('da-DK')
			},
			getImprintName(type){
				if (type === 'W') {
					return this.aapCement[type].name.split(' ')[1]
				}
				return this.aapCement[type].name.split(' ')[0]
			},
			getImprint(type){
				let map = {
					B: .728,
					R: .803,
					F: .574,
					W: 1.04
				}
				return Number((parseInt(this.kg) * map[type]).toFixed(1)).toLocaleString("da-DK")
			},
			newCalculation(){
				this.reset('all')
				window.scrollTo(0, 0)
			},
			calcIsDesktop(){
				this.isDesktop = (window.innerWidth > 991) ? true : false
			},
            getDeeplink(){
                let link = {}
                let siteurl = 'https://beregner.aalborgportland.dk'

                if(this.type !== "private"){link["type"] = "prof"}
                if(this.constructionType !== ""){link["constructionType"] = this.constructionType}
                if(this.categoryType !== ""){link["categoryType"] = this.categoryType}
                if(this.geometryType !== ""){link["geometryType"] = this.geometryType}
                if(this.subCategoryType !== ""){link["subCategoryType"] = this.subCategoryType}
                if(this.l !== ""){link["l"] = this.l}
                if(this.w !== ""){link["w"] = this.w}
                if(this.h !== ""){link["h"] = this.h}
                if(this.bt !== ""){link["w"] = this.bt}
                if(this.r !== ""){link["r"] = this.r}
                if(this.t !== ""){link["t"] = this.t}
                if(this.sm !== false){link["sm"] = this.sm}
                if(this.showResult !== false){link["showResult"] = true}

                if(Object.keys(link).length > 0){
                    this.deeplinkString = siteurl + '?' + queryString.stringify(link)
                    this.deeplinkPopupActive = true
                }
            },
            closeDeeplinkPopup(){
                this.deeplinkPopupActive = false
                setTimeout(function(){
                    this.deeplinkString = '';
                }, 300);
            },
            closePopup(){
                this.popupActive = false;
                setTimeout(function(){
                    this.popupContent = '';
                }, 300);
            },
            closeIntroPopup(){
                this.introPopupActive = false;
            },
            openIntroPopup(){
                this.introPopupActive = true;
            },
            setPopupData(data){
                if(this.popupActive) {
                    this.closePopup()
                }else{
                    this.popupActive = true;
                    this.popupContent = data.popup
                }
            },
			isFoundationBlocks(){
				return this.constructionType === 'cat1' && this.categoryType === 'type1' && this.type === "private";
			},
            closeTastSelvPopupBtn: function(){
                this.reset('categoryType')
            },
            confirmTastSelv: function(){
                this.tastSelvPopupSelected = true
            },
            inArray: function(needle, haystack) {
                var length = haystack.length;
                for(var i = 0; i < length; i++) {
                    if(haystack[i] == needle) return true;
                }
                return false;
            },
            replaceDotToComma: function(n) {
                n = n.replace(/\./g, ',');
                return n;
            },
		    getNumberRealNumber: function (n) {
				if (!n) return 0;
				var decimalCount = (this.getNumberOfDecimals(n) > 2) ? 2 : this.getNumberOfDecimals(n);
				return this.replaceDotToComma(parseFloat(n).toFixed(decimalCount)).toLocaleString("da-DK");
		    },
		    getNumberOfDecimals: function (x) {
				if (Math.floor(x) === x) return 0;
				var split = x.toString().split(".");
				var output = false;
				if (split.length > 1) {
					 output = x.toString().split(".")[1].length;
				} else {
					 output = x.toString().split(".")[0].length;
				}
				return output || 0;
		    },
            toggleStobemix(){
                if(this.sm === true) {
                    this.sm = false
                }else{
                    this.sm = true
                }
            },
            toggleDropdown(){
                if(this.dda === true) {
                    this.dda = false
                }else{
                    this.dda = true
                }
            },
            reset(resetType){

                if(resetType !== 'formData')
                {
                    this.l = '';
                    this.w = '';
                    this.h = '';
                    this.r = '';
                    this.t = '';
                    this.bt = '';
                    this.blocksNeeded = 0;
                    this.volumen = 0;
                    this.concreteVolume = 0;
                    this.areal = 0;
                    this.sm = false;
                    this.dataReady = false;
                }

                this.dda = false;
                this.showResult = false;

                if(resetType === 'constructionType' || resetType === 'all'){
                    this.setSubCategoryType('');
                    this.setGeometryType('');
                    this.setCategoryType('');
                    this.setConstructionType('');
                    this.setStepped(1);
                }
                if(resetType === 'categoryType'){
                    this.setSubCategoryType('');
                    this.setGeometryType('');
                    this.setCategoryType('');
                    this.setStepped(2);
                }
                if(resetType === 'geometryType'){
                    this.setGeometryType('');
                    this.setStepped(3);
                }
                if(resetType === 'subCategoryType'){
                    this.setSubCategoryType('');
                    this.setStepped(3);
                }
                if(resetType === 'formData')
                {
                    this.setStepped(4);
                }

            },
            setType(key){
                if(this.constructionType === ''){
                    this.type = key
                }else{
                    this.reset('all')
                    this.type = key
                }
            },
            setConstructionType(key){
                this.setStepped(2);
                this.tastSelvPopupSelected = false
                if(key === 'cat1' || key === 'cat2' || key === ''){
                    this.setSteps(5);
                }else{
                    this.setSteps(4);
                }
                this.constructionType = key
                this.closePopup()
            },
			setCategoryType(key) {
				this.categoryType = key;
				this.tastSelvPopupSelected = false;

				// Special case for skipping step 3 (geometry selection) when constructionType is 'cat1' and categoryType is 'type1'
				if (this.constructionType === 'cat1' && key === 'type1' && this.type !== "prof") {
					// Set steps to 4, and skip step 3 (geometry step)
					this.setSteps(4);
					this.setStepped(3);  // Directly go to step 4 (skipping step 3)
				} else {
					this.setStepped(3);
					this.tastSelvPopupSelected = false
					this.categoryType = key
				}

				this.closePopup();
			},
			setGeometryType(key){
                this.setStepped(4);
                this.geometryType = key
                this.closePopup()
            },
            setSubCategoryType(key){
                this.setStepped(4);
                this.subCategoryType = key
                this.closePopup()
            },
            calcResult(){
                this.setStepped(this.steps.length);
                this.showResult = true;
            },
            setSteps(count){
                let steps = [];
                for(let i=0; i < count; i++){
                    steps.push(i+1);
                }
                this.steps = steps
            },
            setStepped(count){
                let steps = [];
                for(let i=0; i < count; i++){
                    steps.push(i+1);
                }
                this.stepped = steps
            },
            printReceipt(e){
                let path = e.path;
                for(let p=0; p < path.length; p++){
                    if(path[p].id === "receipt")
                    {
                        let printHtml = path[p].outerHTML;
                        let windowContent = '<!DOCTYPE html>';
                        windowContent += '<html style="width: 100%; height: 100%; margin: 0px; padding: 0px;">'
                        windowContent += '<head><title>Print canvas</title></head>';
                        windowContent += '<body style="width: 100%; height: 100%; margin: 0px; padding: 0px;">'
                        windowContent += '<style>*{font-family: Helvetica, Arial, sans-serif; font-size: 16px;}body{padding: 25px 25px 0px !important;height: auto !important;}.aap-logo{margin-bottom: 30px;}.title{font-size: 30px;}.sub-title{font-size: 22px; margin-bottom: 15px;}.sub-desc{margin-bottom: 30px;}.col{float: left; width: 50%; min-height: 1px;}.col ul{margin: 0px; padding: 0px;}.col ul li{list-style: none;}.col img{position: relative; top: -25px; left: -50px; max-width: 300px;}.print{display: none;}.ribbons{display: none;}.heading,.choices,.mixin,.volume,.concreteProps,.recommend{margin-bottom: 30px;}.aap-logo{text-align:right; display: block;}img{max-width: 150px !important; height: auto;}.buttons{display:none}.toggle-wrapper{display:none}<\/style>'; //eslint-disable-line
                        windowContent += printHtml;
                        windowContent += '<script>setTimeout(function(){window.print();},300);<\/script>'; //eslint-disable-line
                              // @todo: Det virker som om der er et loop her, hvis jeg fjerner skråstregen virker js ikke da den breaker på det lukkende script tag - den tror det er for vue script sektionen.
                        windowContent += '</body>';
                        windowContent += '</html>';
                        let printWin = window.open('', '', 'top=100,left=100,width=550,height=800');
                        printWin.document.open();
                        printWin.document.write(windowContent);
                        break;
                    }
                }
            },
			blockTypeVolumen() {
				const volumes = {
					'15': 0.07,
					'19': 0.11,
					'23': 0.14,
					'29': 0.19,
					'33': 0.23,
					'39': 0.28,
				};
				return volumes[this.bt] || 0;
			},
			calculateConcreteAndBlocks() {
				// Step 1: Get the concrete volume per m² for the chosen block type
				const concretePerSquareMeter = this.blockTypeVolumen();  // Volume per m² in m³

				// Step 2: Calculate the total wall area in m² (height * length, both in meters)
				const wallArea = this.l * this.h;

				// Step 3: Calculate the number of blocks needed (10 blocks per m²)
				const numberOfBlocks = wallArea * 10;

				// Step 4: Calculate the total concrete volume (wall area * concrete per m²)
				const totalVolume = wallArea * concretePerSquareMeter;

				// Step 5: Format and store the result
				this.blocksNeeded = new Intl.NumberFormat('da-DK').format(numberOfBlocks);  // Format the number of blocks with dot separators

				return {
					concreteVolume: totalVolume, // Corrected total volume in m³
					blocks: numberOfBlocks // Raw number of blocks needed
				};
			},
			checkForReady(){
				if (this.isFoundationBlocks()) {
					// Call calculateConcreteAndBlocks() to get volume and block count
					const result = this.calculateConcreteAndBlocks();

					// Set the volume from the returned concrete volume
					this.volumen = result.concreteVolume;

					// Set dataReady based on whether the volume is greater than 0
					this.dataReady = (this.volumen !== 0);

					// Log for debugging purposes
					// console.log(`Concrete Volume: ${this.volumen} m³`);
					// console.log(`Blocks Needed: ${result.blocks}`);
				}

                if(this.constructionType === 'cat1' && this.geometryType === 'shape1')
                {
                    let check = this.l * this.w * this.h;
                    this.volumen = check;
                    this.dataReady = (check !== 0);
                }

                if(this.constructionType === 'cat1' && this.geometryType === 'shape2'){
                    let check = this.h * (this.r * this.r) * Math.PI;
                    this.volumen = check;
                    this.dataReady = (check !== 0);
                }

                if(this.constructionType === 'cat1' && this.geometryType === 'shape3'){
                    let check = (this.l * 0.5) * this.w * this.h;
                    this.volumen = check;
                    this.dataReady = (check !== 0);
                }

                if(this.constructionType === 'cat2'){
                    let check = this.h * this.l;
                    this.volumen = check;
                    this.areal = check.toFixed(0);
                    this.dataReady = (check !== 0);
                }

                if(this.constructionType === 'cat3'){
                    let areal = this.h * this.w;
                    let volume = this.h * this.w * (this.t / 1000);
                    this.volumen = volume;
                    this.areal = areal.toFixed(0);
                    this.dataReady = (volume !== 0);
                }

                if(this.constructionType === 'cat4'){
                    let areal = this.l * this.w;
                    let volume = this.l *  this.w * (this.t / 1000);
                    this.volumen = volume;
                    this.areal = areal.toFixed(0);
                    this.dataReady = (volume !== 0);
                }

            }
        },
        watch: {
            l: function(){
                this.checkForReady()
            },
            w: function () {
                this.checkForReady()
            },
            h: function () {
                this.checkForReady()
            },
            r: function () {
                this.checkForReady()
            },
            t: function () {
                this.checkForReady()
            }
        }
    }
</script>
<style lang="scss">
@import "../assets/css/main.scss";
</style>
